import { render, staticRenderFns } from "./PatientVisitsHistoric.vue?vue&type=template&id=413ab084&scoped=true&"
import script from "./PatientVisitsHistoric.vue?vue&type=script&lang=ts&"
export * from "./PatientVisitsHistoric.vue?vue&type=script&lang=ts&"
import style0 from "./PatientVisitsHistoric.vue?vue&type=style&index=0&id=413ab084&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "413ab084",
  null
  
)

export default component.exports