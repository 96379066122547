
import { Vue, Component } from "vue-property-decorator";
import VisitDropdown from "@/components/visits/VisitDropdown.vue";
import { get } from "@/api";
import { Session } from "@/interfaces/therapy";
import { displaySpanRange } from "@/views/therapistCalendar/helpers";
import { sessionDate } from "./helpers";
import i18n from "@/i18n";
const pageKey = "history";

@Component({
  components: {
    VisitDropdown,
  },
  methods: {
    displaySpanRange,
    sessionDate,
  },
  metaInfo: {
    title: i18n.t(`page-title.${pageKey}`).toString(),
    meta: [
      {
        name: "description",
        content: i18n.t(`meta-description.${pageKey}`).toString(),
      },
    ],
  },
})
export default class MyVisitsPending extends Vue {
  historicSessions: Session[] = [];
  loaded = false;

  mounted() {
    this.getSessions();
  }
  async getSessions() {
    try {
      this.getHistoricSessions();
    } catch (error) {
      console.log(error);
    }
  }

  async getHistoricSessions() {
    const response = await get.sessions();
    this.historicSessions = response.data.filter(
      (session) => new Date().getTime() > session.span.start
    );
    this.historicSessions.sort((session1, session2) => {
      if (session1.span.start > session2.span.start) {
        return -1;
      } else {
        return 1;
      }
    });
    this.loaded = true;  }
}
